/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { jsx } from "@emotion/core"
import tw from "tailwind.macro"
import { animated, useSpring } from "react-spring"

import config from "../config/index"

const Books = ({ data }) => {
  const BookContainer = styled.div`
    ${tw`
absolute flex flex-row flex-no-wrap right-0 top-0 h-full justify-end align-middle mr-16
`}
  `

  const [isHover, setIsHover] = useState(false)
  const [isHoverParis, setIsHoverParis] = useState(false)
  const [isHoverSpain, setIsHoverSpain] = useState(false)

  const animation = useSpring({
    transform: isHover ? `scale(1.05)` : `scale(1)`,
  })
  const animationParis = useSpring({
    transform: isHoverParis ? `scale(1.05)` : `scale(1)`,
  })
  const animationSpain = useSpring({
    transform: isHoverSpain ? `scale(1.05)` : `scale(1)`,
  })

  const animationParisLearnMore = useSpring({
    transform: isHoverParis ? `scale(1.05)` : `scale(1)`,
  })

  return (
    <div className="flex flex-col mt-8 pt-8 border-t border-gray-100">
      <h2 className="font-heading text-2xl tracking-widest uppercase text-center text-heading">
        Books
      </h2>
      
      <div className="mt-10 mb-4 mx-auto w-full flex flex-col md:flex-row justify-between">


      <div
          className="relative w-full md:w-1/3 mb-4 md:mb-0 md:mr-2 overflow-hidden z-auto"
          css={{
            height: "630px",
          }}
        >
          <div className="absolute h-64 h-full w-full">
            <Img
              className="object-cover h-full w-full"
              fluid={data.spanishSun.childImageSharp.fluid}
            />
          </div>
          <div
            css={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "block",
              top: "0",
              left: "0",
              backgroundColor: "rgba(254, 87, 28, 0.95);",
              
            }}
          ></div>
          <div className="bookDetails w-full h-full justify-center align-middle flex flex-col">
            <h2 className="z-50 w-full text-3xl text-center text-white font-heading md:mb-5">
              Shadows Over the Spanish Sun
            </h2>
            <span className="inline-block  px-6  z-50 w-full text-xl text-center text-white">
            A country in the shadow of war. A love that burns through the decades...
            </span>
            <div className="z-50 flex flex-row justify-center mt-4">
              <Link
                to="/shadows-over-the-spanish-sun"
                className="inline-block text-white hover:text-black z-50 mr-12"
                css={{
                  transition: "0.7s all",
                }}
              >
                Learn more >
              </Link>
              <a
                href={config.buylinks.spanishLink}
                rel="nofollow noopener"
                target="_blank"
                className=" hover:scale-110 inline-block text-white hover:text-black z-50"
                css={{
                  transition: "0.7s all",
                }}
              >
                Buy now >
              </a>
            </div>
            <animated.div
              onMouseEnter={() => setIsHoverSpain(true)}
              onMouseLeave={() => setIsHoverSpain(false)}
              style={animationSpain}
              className="z-50 flex flex-row justify-center mt-10 mx-auto w-40 h-auto z-50 cursor-pointer"
            >
              <Link to="/shadows-over-the-spanish-sun" className="block w-full h-full">
                <Img
                  className="object-cover z-50 w-full h-full"
                  css={{
                    boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
                  }}
                  fluid={data.spanishSunThumb.childImageSharp.fluid}
                />
              </Link>
            </animated.div>
          </div>
        </div>

        <div
          className="relative w-full md:w-1/3 mb-4 md:mb-0 md:mr-2 overflow-hidden z-auto"
          css={{
            height: "630px",
          }}
        >
          <div className="absolute h-64 h-full w-full">
            <Img
              className="object-cover h-full w-full"
              fluid={data.italianAffair.childImageSharp.fluid}
            />
          </div>
          <div
            css={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "block",
              top: "0",
              left: "0",
              backgroundColor: "rgba(62, 178, 175, 0.95);",
            }}
          ></div>
          <div className="bookDetails w-full h-full justify-center align-middle flex flex-col">
            <h2 className="z-50 w-full text-3xl text-center text-white font-heading md:mb-5">
              An Italian Affair
            </h2>
            <span className="inline-block  px-6  z-50 w-full text-xl text-center text-white">
              A sweeping tale of love, betrayal and war...
            </span>
            <div className="z-50 flex flex-row justify-center mt-4">
              <Link
                to="/an-italian-affair"
                className="inline-block text-white hover:text-black z-50 mr-12"
                css={{
                  transition: "0.7s all",
                }}
              >
                Learn more >
              </Link>
              <a
                href={config.buylinks.italianLink}
                rel="nofollow noopener"
                target="_blank"
                className=" hover:scale-110 inline-block text-white hover:text-black z-50"
                css={{
                  transition: "0.7s all",
                }}
              >
                Buy now >
              </a>
            </div>
            <animated.div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              style={animation}
              className="z-50 flex flex-row justify-center mt-10 mx-auto w-40 h-auto z-50 cursor-pointer"
            >
              <Link to="/an-italian-affair" className="block w-full h-full">
                <Img
                  className="object-cover z-50 w-full h-full"
                  css={{
                    boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
                  }}
                  fluid={data.italianAffairThumb.childImageSharp.fluid}
                />
              </Link>
            </animated.div>
          </div>
        </div>

        <div
          className="relative w-full md:w-1/3 ml-0 overflow-hidden z-auto pr-4"
          css={{
            height: "630px",
          }}
        >
          <div className="absolute h-64 h-full w-full">
            <Img
              className="object-cover h-full w-full"
              fluid={data.parisSecret.childImageSharp.fluid}
            />
          </div>
          <div
            css={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "block",
              top: "0",
              left: "0",
              backgroundColor: "rgba(41, 171, 226, 0.95);",
            }}
          ></div>
          <div className="bookDetails w-full h-full justify-center align-middle flex flex-col">
            <h2 className="z-50 w-full text-3xl text-center text-white font-heading md:mb-5">
              A Paris Secret
            </h2>
            <span className="inline-block z-50 w-full px-6 text-xl text-center text-white">
              A powerful promise. A terrible sacrifice. A second chance...{" "}
            </span>
            <div className="z-50 flex flex-row justify-center mt-4">
              <Link
                to="/a-paris-secret"
                className="inline-block text-white hover:text-black z-50 mr-12"
                css={{
                  transition: "0.7s all",
                }}
              >
                Learn more >
              </Link>
              <a
                href={config.buylinks.parisLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-white hover:text-black z-50"
                css={{
                  transition: "0.7s all",
                }}
              >
                Buy now >
              </a>
            </div>
            <animated.div
              onMouseEnter={() => setIsHoverParis(true)}
              onMouseLeave={() => setIsHoverParis(false)}
              style={animationParis}
              className="z-50 flex flex-row justify-center mt-10 mx-auto w-40 h-auto z-50"
            >
              <Link to="/a-paris-secret" className="block w-full h-full">
                <Img
                  className="object-cover z-50 w-full h-full"
                  css={{
                    boxShadow: "-3px 3px 5px rgba(0 ,0, 0, 0.15);",
                  }}
                  fluid={data.parisSecretThumb.childImageSharp.fluid}
                />
              </Link>
            </animated.div>
          </div>
        </div>



      </div>
    </div>
  )
}

export default Books
